<template>
  <el-dialog
    title="Change Customer IBNA"
    :visible.sync="shouldShow"
    append-to-body
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-form-item label="Select IBNA status">
        <el-radio-group v-model="form.ibna">
          <el-radio label="active">Active</el-radio>
          <el-radio :label="null">Inactive</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="primary" :loading="loading" @click="changeStatus"
        >Save</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import customer from "@/requests/customers/customer/actions";

export default {
  name: "CustomerIbnaChange",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        ibna: null,
      },
      loading: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.ibna = this.customer.ibna;
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    changeStatus() {
      this.loading = true;
      customer
        .ibna(this.customer.id, this.form)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success", { ibna: this.form.ibna });
            this.closeEvent();
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
